export function hexify(color) {
    let str = color.slice(5,color.length - 1),
		arry = str.split(','),
		opa = Number(arry[3].trim())*100,
		strHex = "#",   
		r = Number(arry[0].trim()),
		g = Number(arry[1].trim()),
		b = Number(arry[2].trim());
	
	strHex += ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    
    return strHex;
    //   var values = color
    //     .replace(/rgba?\(/,").replace((/\)/,")
    //     .replace(/[\s+]/g, '')
    //     .split(',');
    //   var a = parseFloat(values[3] || 1),
    //       r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
    //       g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
    //       b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
    //   return "#" +
    //     ("0" + r.toString(16)).slice(-2) +
    //     ("0" + g.toString(16)).slice(-2) +
    //     ("0" + b.toString(16)).slice(-2);
    }
    export function hexToRgba(hex, opacity="1") {
        return "rgba(" + parseInt("0x" + hex.slice(1, 3)) + "," + parseInt("0x" + hex.slice(3, 5)) + "," + parseInt("0x" + hex.slice(5, 7)) + "," + opacity + ")";
    }
    var myHex = hexify('rgba(238,238,238,1)'); // "#f5faf3"
    