<template>
  <div>
    <basic-container>
    <avue-crud :option="tableOption"
                 :data="tableData"
                 :table-loading="tableLoading"
                 :page="page"
                 ref="crud"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @selection-change="selectionChange"
                 @refresh-change="refreshChange"
                 @row-save="handleSave"
                 @row-update="handleUpdate"
                 @row-del="handleDel">
            <template slot="status" slot-scope="scope">
                <span style="color: green;" v-if="scope.row.status==0">{{$t('ENABLE')}}</span>
                <span style="color: red;" v-else>{{$t('DISABLE')}}</span>
                <!-- <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                @change="change(scope.row)">
                </el-switch> -->
            </template>
        <template slot="menuLeft">
          <importBtn
              :outApi="this.proApis.TUBECOLOREXPORT"
              :innerAPI="this.proApis.TUBECOLORIMPORT"
              :API="this.proApis.TUBECOLORIMPORTTEMPLATE"
              @refresh="refreshData"
          ></importBtn>
        </template>
        <template slot="menuRight">
            <!--<el-select clearable v-model="status" placeholder="状态" size="small" style="width:100px !important;marginRight: 5px;">
                <el-option label="启用" value="0">启用</el-option>
                <el-option label="禁用" value="1">禁用</el-option>
            </el-select>-->
            <el-input v-model="nameOrCode" :placeholder="$t('PIPE RING NAME OR CODE')" style="display:inlie-block;width:200px;marginRight: 5px;" size="small"></el-input>
        </template>
        <!-- <template slot="menu">
            <el-button type="text" size="mini" icon="el-icon-discover" style="color:red;">{{$t('DISABLE')}}</el-button>
        </template> -->
    </avue-crud>
    </basic-container>
  </div>
</template>
<script>
import {list,changeStatus,add,edit,remove} from "@/api/color"
import importBtn from "@/components/importExport/importBtn";
import {hexify,hexToRgba} from "@/util/rgbato";
export default {
  components:{
    importBtn
  },
    data() {
        return {
            tableLoading: false,
            nameOrCode: "",
            status: "0",
            page: {
                pageSize: 20,
                pagerCount: 5,
                total: 10,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                //delBtnText:'禁用',
                delBtn: true,
                menuWidth: 200,
                selection: true,
                tip: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                column: [{
                    label: this.$t('NAME'),
                    prop: "name",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('CODE'),
                    prop: "code",
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE CODE'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('DATA STATUS'),
                    value: 0,
                    type: "select",
                    prop: "status",
                    slot: true,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE SELECT DATA STATUS'),
                        trigger: "blur"
                    }],
                    dicData: [{
                        label: this.$t('ENABLE'),
                        value: 0
                    }, {
                        label: this.$t('DISABLE'),
                        value: 1
                    }]
                }, {
                    label: this.$t('PIPE RING TYPE'),
                    display: false,
                    hide: true,
                    prop: "type",
                    type: "color",
                    dicData: [{
                        label: this.$t('ENABLE'),
                        value: 0
                    }, {
                        label: this.$t('DISABLE'),
                        value: 1
                    }]
                }, {
                    label: this.$t('COLOR VALUE'),
                    //colorFormat:"hex",
                    //type: "color",
                    prop: "colorValue"
                }, {
                    label: this.$t('CREATOR'),
                    prop: "corpUsersName",
                    editDisplay: false,
                    addDisplay: false
                }, {
                    label: this.$t('CREATION TIME'),
                    prop: "createTime",
                    editDisplay: false,
                    addDisplay: false
                }, {
                    label: this.$t('REMARK'),
                    display: false,
                    prop: "textar",
                    hide: true
                }]
            }
        }
    },
    created() {
      this.list();
    },
    methods: {
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        refreshData() {
          this.list();
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'),{
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then(res => {
                    res.data.code=="0000"?this.$message({
                      type:"success",
                        message: this.$t('DeleteSuccess')
                    }):"";
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            //row.colorValue = hexify(row.colorValue);
            edit(row).then(res => {
                res.data.code=="0000"?this.$message({
                  type:"success",
                    message: this.$t('EDIT SUCCEEDED')
                }):"";
                this.list();
                done();
            })
        },
        handleSave(row, done) {
            //row.colorValue = hexify(row.colorValue);
            console.log(row)
            add(row).then(res => {
                this.list();
                done();
            })
        },
        change(row) {
            changeStatus(row)
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
          this.tableData = [];
          list(this.page.pageCurrent,this.page.pageSize,this.nameOrCode,this.status).then(res => {
              this.page.total = res.data.data.total;
              //res.data.data.items.forEach(v => {
              //  v.colorValue = hexToRgba(v.colorValue);
              //});
              this.tableData = res.data.data.items;
              this.tableData.forEach(v => {
                  v.status = v.status;
              })
          })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown .el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
        padding-left: 10px;
    }
</style>