var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      scope.row.status == 0
                        ? _c("span", { staticStyle: { color: "green" } }, [
                            _vm._v(_vm._s(_vm.$t("ENABLE")))
                          ])
                        : _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(_vm.$t("DISABLE")))
                          ])
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _c("importBtn", {
                    attrs: {
                      outApi: this.proApis.TUBECOLOREXPORT,
                      innerAPI: this.proApis.TUBECOLORIMPORT,
                      API: this.proApis.TUBECOLORIMPORTTEMPLATE
                    },
                    on: { refresh: _vm.refreshData }
                  })
                ],
                1
              ),
              _c(
                "template",
                { slot: "menuRight" },
                [
                  _c("el-input", {
                    staticStyle: {
                      display: "inlie-block",
                      width: "200px",
                      marginRight: "5px"
                    },
                    attrs: {
                      placeholder: _vm.$t("PIPE RING NAME OR CODE"),
                      size: "small"
                    },
                    model: {
                      value: _vm.nameOrCode,
                      callback: function($$v) {
                        _vm.nameOrCode = $$v
                      },
                      expression: "nameOrCode"
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }