import request from '@/router/axios';
import {businessUrl} from "@/config/env"
export const list = (pageNo,pageSize,nameOrCode,status) => {
    return request({
        url : businessUrl + "tubeColor/selectPage",
        method: "post",
        data: {
            pageNo,
            pageSize,
            nameOrCode: nameOrCode,status
        }
    })
}
//编辑
export const edit = (row) => {
    return request({
        url : businessUrl + "tubeColor/updateByPrimaryKeySelective",
        method: "put",
        data: {
            ...row
        }
    })
}
//删除
export const remove = (id) => {
    return request({
        url : businessUrl + "tubeColor/delete",
        method: "delete",
        params: {
            id
        }
    })
}
export const changeStatus = (row) => {
    return request({
        url : businessUrl + "tubeColor/updateStatus",
        method: "put",
        data : {
            id : row.id,
            status : Number(row.status)
        }
    })
}
export const add = (row) => {
    return request({
        url : businessUrl + "tubeColor/insert",
        method: "post",
        data : {
            type: 2,
            ...row,
            status: 1
        }
    })
}
export const  selectByPromayKey = (id,auditStatus) => {
    return request({
        url : businessUrl + "tubeColor/selectByPromayKey",
        method: "put",
        data: {
            id,
            auditStatus
        }
    })
}
